import { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { ErrorSnackbar, InfoSnackbar } from '@onc/composite-components';
import PageWithPadding from 'library/CompositeComponents/page/PageWithPadding';

import TestHistoryTable from './TestHistoryTable';
import LiveDafTestService from '../LiveDafTestService';

class TestHistoryTableContainer extends Component {
  static propTypes = {
    match: PropTypes.shape({
      path: PropTypes.string,
    }).isRequired,
  };

  state = {
    historyList: [],
    error: null,
    rowClicked: false,
    actionClicked: false,
    stop: false,
  };

  componentDidMount() {
    this.getTestHistory();
  }

  async handleStopTest(id) {
    this.setState({ actionClicked: true });
    try {
      await LiveDafTestService.cancel(id);
      this.setState({
        stop: true,
        actionClicked: false,
        rowClicked: false,
      });
    } catch (error) {
      this.setState({
        error,
      });
    }
  }

  onErrorClose = () => {
    this.setState({ error: undefined });
  };

  getTestHistory() {
    return LiveDafTestService.getAll()
      .then((historyList) => {
        this.setState({ historyList });
      })
      .catch((error) => this.setState({ error }));
  }

  handleRowClicked = (row) => {
    this.setState({ rowClicked: row });
  };

  render() {
    const { error, historyList, rowClicked, actionClicked, stop } = this.state;
    const { match } = this.props;

    if (error) {
      return (
        <ErrorSnackbar message={error.message} onClose={this.onErrorClose} />
      );
    }
    if (stop) {
      return (
        <InfoSnackbar
          message="Stopping test"
          onClose={() => this.setState({ stop: false })}
        />
      );
    }
    if (rowClicked && !actionClicked) {
      return <Redirect push to={`/live-daf-testing/test/${rowClicked}`} />;
    }

    return (
      <PageWithPadding>
        <TestHistoryTable
          historyList={historyList}
          match={match}
          handleRowClicked={this.handleRowClicked}
          handleStopTest={this.handleStopTest}
        />
      </PageWithPadding>
    );
  }
}
export default TestHistoryTableContainer;
